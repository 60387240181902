@import '../../sass/variables';
@import '../../sass/mixins';

.category-items {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  padding: 0 5em;
  @include mobileLgMaxBreakPoint() {
    padding: 0 2em;
    .category-items__productWrapper {
      img {
        height: 50px;
        width: 50px;
      }
    }
  }
  &__banner {
    align-items: center;
    background-color: $extraLightBlue;
    border-radius: 1.4em;
    display: flex;
    flex-direction: column;
    height: 320px;
    justify-content: center;
    margin: 2em 0 3em;
    width: 100%;
    img {
      width: 100px;
    }
    h2 {
      color: $extraDarkBlue;
      font-size: 1.7em;
      font-weight: 600;
      line-height: 0.9;
      margin: 0;
      // margin-top: 0.5em;
    }
    p {
      color: $extraDarkBlue;
      font-size: 0.7em;
      font-weight: 500;
      margin: 0;
    }
  }
  &__header {
    text-align: left;
    width: 100%;
  }
}
