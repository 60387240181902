@import '../../sass/variables';
@import '../../sass/mixins';

.reports {
  margin: 0 10%;
  line-height: 1.8em;
  h2 {
    color: $darkBlue;
    font-size: 1.6em;
    font-weight: 500;
  }
  h3 {
    color: $darkBlue;
    font-size: 1.4em;
    font-weight: 400;
  }
}




.categories {
  &-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    padding: 0 5em;
    &__route {
      color: $darkBlue;
      font-size: 0.9em;
      font-weight: 500;
      margin: 0;
      svg {
        margin: 0 1em;
      }
      a {
        cursor: pointer;
        color: $darkBlue;
        opacity: 0.4;
        text-decoration: none;
      }
      a:last-child {
        opacity: 1;
      }
    }
    &__title {
      color: $darkBlue;
      font-size: 2em;
      font-weight: 500;
      margin: 0;
      margin-top: 0.8em;
    }
    &__add {
      background-color: $blue;
      border: none;
      border-radius: 6px;
      color: $white;
      height: 40px;
      outline: none;
      width: 100px;
    }
    @include smMaxBreakPoint() {
      padding: 0 2em;
      &__add {
        width: 60px;
        height: 30px;
      }
    }
  }
}
.flexbox-container_pie_chart{
  display: flex;
  flex-direction: row;
  height: 1200px;
  width: 1200px;
  align-items: top;
  padding: 25 2em;
}

.pie_chart_small {
  align-items: center;
  border: 1px solid $lightGray;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(41, 47, 79, 0.02), 0px 0px 19px rgba(41, 47, 79, 0.03);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 800px;
  justify-content: center;
  margin: 100px;
  p {
    font-size: 1em;
    margin: 0;
    margin-top: 1em;
  }
  img {
    height: 550px;
    object-fit: cover;
    width: 550;
  }
  @include lgMaxBreakPoint() {
    height: 180px;
  }
  @include smMaxBreakPoint() {
    height: 140px;
  }
}