@import '../../sass/variables';

.searchResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3em;
  &__recentTitle {
    align-items: center;
    display: flex;
    div {
      align-items: center;
      background-color: $lightGray;
      border-radius: 50%;
      color: $darkBlue;
      display: flex;
      font-size: 1em;
      height: 22px;
      justify-content: center;
      margin-left: 0.5em;
      width: 22px;
    }
  }
  &-recentKeywordsWrapper {
    display: flex;
    &__keyword {
      background-color: $lightGray;
      border-radius: 40px;
      margin-right: 0.5em;
      padding: 0.5em;
    }
  }
  &__description {
    font-size: 0.9em;
    margin: 1em 0 3em;
  }
}
