@import './variables';

// Breakpoints
@mixin mobileSmMaxBreakPoint {
  @media (max-width: #{$mobile-sm}) {
    @content;
  }
}

@mixin mobileMdMaxBreakPoint {
  @media (max-width: #{$mobile-md}) {
    @content;
  }
}

@mixin mobileLgMaxBreakPoint {
  @media (max-width: #{$mobile-lg}) {
    @content;
  }
}

@mixin smMaxBreakPoint {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}

@mixin mdMaxBreakPoint {
  @media (max-width: #{$screen-md}) {
    @content;
  }
}

@mixin lgMaxBreakPoint {
  @media (max-width: #{$screen-lg}) {
    @content;
  }
}

@mixin xlMaxBreakPoint {
  @media (max-width: #{$screen-xl}) {
    @content;
  }
}

@mixin xxlMaxBreakPoint {
  @media (max-width: #{$screen-xxl}) {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+'px') {
    @content;
  }
}

@mixin customMaxBreakPoint($size) {
  @media (max-width: $size+'px') {
    @content;
  }
}
