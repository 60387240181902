// Screen sizes
$mobile-sm: 320px;
$mobile-md: 375px;
$mobile-lg: 425px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1300px;
$screen-full: 1400px;

// Colors
$textColor: #42474c;
$textLight1Color: #6b7177;

$primaryDark2Color: #002e25;
$primaryDark1Color: #004c3f;
$primaryColor: #008060;
$primaryLight1Color: #c1f0d0;
$primaryLight2Color: #effcfa;

$secondaryColor: #906509;
$secondaryLight1Color: #fbf7ed;
$secondaryLight2Color: #fff8f6;
$secondaryLight3Color: #e2e6dc;
$secondaryLight4Color: #ede8db;
$background: #e5e5e5;

$white: #ffffff;
$black: #000000;
$darkBlue: #292f4f;
$extraDarkBlue: #1b1f33;
$lightGray: #ebecf0;
$blue: #3d60f6;
$extraLightBlue: #f6f7fc;

$warningColor: #c43256;
$warningLight1Color: #c432571f;
