@import '../../sass/variables';
@import '../../sass/mixins';

.inventoryDetails {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1em;
  padding: 0 5em;
  @include mobileLgMaxBreakPoint() {
    padding: 0 1em;
  }
  &-header {
    text-align: left;
    width: 100%;
    &__row {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 0.5em;
      h2 {
        color: $darkBlue;
        font-size: 1.6em;
        font-weight: 500;
        margin: 0;
      }
      div {
        align-items: center;
        display: flex;
        justify-content: center;
        svg {
          cursor: pointer;
          margin: 0 0.5em;
        }
      }
    }
  }
  &-images {
    align-items: center;
    display: flex;
    height: 30em;
    justify-content: center;
    margin: 1em 0;
    max-height: 30em;
    width: 100%;
    &__main {
      border-radius: 1.4em 0 0 1.4em;
      background-color: $extraLightBlue;
      height: 100%;
      margin-right: 0.5em;
      width: 50%;
      img {
        border-radius: 1.4em 0 0 1.4em;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    &__smallImagesWrapper {
      display: grid;
      gap: 0.5em;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(e, 1fr);
      height: 100%;
      width: 50%;
      .inventoryDetails-images__smallImage {
        background-color: $extraLightBlue;
        height: 14.75em;
        width: 100%;
        img {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
        &:nth-child(2) {
          border-radius: 0 1.4em 1.4em 0;
        }
        &:last-child {
          border-radius: 0 1.4em 1.4em 0;
        }
      }
    }
  }
  &-labels {
    .label {
      margin-right: 0.5em;
    }
  }
  &-details {
    margin-top: 1em;
    &__item {
      border-bottom: 1px solid $lightGray;
      display: flex;
      justify-content: space-between;
      margin: 0.5em 0;
      p {
        color: $darkBlue;
        font-size: 1.2em;
        &:last-child {
          font-weight: 600;
        }
      }
    }
    .inventoryDetails-details__item--documents {
      flex-direction: column;
      p {
        color: $darkBlue;
        font-size: 1.2em;
      }
      .inventoryDetails-details__documentsWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .inventoryDetails-details__document {
          color: $darkBlue;
          cursor: pointer;
          margin: 0.5em 0;
          text-decoration: none;
          img {
            height: 280px;
            margin-right: 1em;
            object-fit: contain;
            width: 280px;
          }
        }
      }
    }
    .inventoryDetails-details__item--note {
      border: none;
    }
  }
}
