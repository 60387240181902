@import '../sass/variables';
@import '../sass/mixins';

.label {
  background: rgba(246, 247, 252, 0.8);
  border-radius: 4px;
  display: inline-block;
  font-size: 0.8em;
  margin: 0;
  padding: 0.5em;
}

.categoriesWrapper {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(e, 1fr);
  margin: 100px 0 0 0;
  padding: 0 5em;
  width: 100%;
  @include xxlMaxBreakPoint() {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mdMaxBreakPoint() {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mobileLgMaxBreakPoint() {
    grid-template-columns: repeat(1, 1fr);
  }
  .category {
    align-items: center;
    border: 1px solid $lightGray;
    border-radius: 10px;
    box-shadow: 0px 0px 4px rgba(41, 47, 79, 0.02), 0px 0px 19px rgba(41, 47, 79, 0.03);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 240px;
    justify-content: center;
    p {
      font-size: 1em;
      margin: 0;
      margin-top: 1em;
    }
    img {
      height: 100px;
      object-fit: cover;
      width: 100px;
    }
    @include lgMaxBreakPoint() {
      height: 180px;
    }
    @include smMaxBreakPoint() {
      height: 140px;
    }
  }
}

.product {
  border-bottom: 1px solid $lightGray;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2em;
  max-width: 1200px;
  padding-bottom: 2em;
  width: 100%;
  .product-wrapper {
    display: flex;
    align-items: center;
    img {
      border-radius: 8px;
      height: 70px;
      width: 70px;
    }
    div {
      margin-left: 1em;
      .product-wrapper__productName {
        color: $darkBlue;
        font-size: 0.9em;
        font-weight: 500;
        margin: 0;
      }
      .product-wrapper__productLabels {
        margin: 0;
        margin-top: 0.5em;
        .label {
          margin-right: 0.5em;
        }
      }
    }
  }
  .product-priceAndDate {
    &__price {
      color: $darkBlue;
      font-weight: 500;
    }
    &__date {
      color: $darkBlue;
      font-size: 0.6em;
      font-weight: 500;
      opacity: 0.4;
    }
  }
}
