@import '../../sass/variables';
@import '../../sass/mixins';

.file-exists {
  text-align: left;
  color: red;
}
.upload-title {
  margin: 0 10%;
  line-height: 1.8em;
  h2 {
    color: $darkBlue;
    font-size: 1.6em;
    font-weight: 500;
  }
  h3 {
    color: $darkBlue;
    font-size: 1.4em;
    font-weight: 400;
  }
}
.drag-drop-zone {
  width: 600;
  height: 400;
  padding: 2rem;
  text-align: center;
  background: #3d60f6;
  border-radius: 0.5rem;
  box-shadow: 5px 5px 10px #C0C0C0;
}

.drag-drop-zone p {
  color: #FFF;
}

.drag-drop-zone.inside-drag-area {
  opacity: 0.7;
}

.dropped-files li {
  color: rgb(0, 247, 255);
  padding: 3px;
  text-align: left;
  font-weight: bold;
}
.upload_button {
  color: rgb(0, 0, 0);
  padding: 3px;
  font-weight: bold;
   // margin-top: -100px;
    text-align: center;
    width: 100%;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

table {

  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  overflow: visible; 
  /* These are just initial values which are overriden using JavaScript when a column is resized */
  grid-template-columns: 
  [Item] 15% [Category] 15% [Desc] 30%  [Date] 12% [Price] 7% [Quantity] 4% [Location] 15% [Delete] 2% ;
  
  grid-template-rows: auto;
  tr:hover {background-color: #ddd;}
}

.relative {
  text-align: center;
  padding: 10px;
  position: relative;
  // background-color: rgb(231, 102, 102);
  margin: 10px;
}

.edit_button {
  background-color: rgb(243, 243, 243);
  border: 5px;
  color: #000000;
  padding: 15px 32px;
  text-align: center;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.6s;
  transition-duration: 0.6s;
  cursor: pointer;
  /*margin: auto;*/
}

thead,
tbody,
tr {
  
  display: contents;
}

th,
td {
  padding: 6px;
  overflow: visible;
  text-overflow: ellipsis;
  white-space: nowrap;
}

th {
  position: sticky;
  top: 0;
  background: #6c7ae0;
  text-align: left;
  font-weight: normal;
  font-size: 1.0rem;
  color: white;
}

.trash_icon {
  padding-top: 5  ;
  justify-content: center;
  color: lightgray;
  justify-content: bottom;
}
th:last-child {
  border: 0;
}

td {
  font-size: 0.8rem;
  padding: 4px;
  text-align: left;
  justify-content: bottom;
  // padding-bottom: 10px;
  color: #808080;
}

tr:nth-child(even) td {
  background: #f8f6ff;
}

