@import './mixins';

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 34px;
  line-height: 1.2em;
  margin: 0;

  @include smMaxBreakPoint {
    font-size: 48px;
  }
}

h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
}

h3 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 125%;
  margin: 0;

  @include customMaxBreakPoint(400) {
    font-size: 18px;
  }
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125%;
  margin: 0;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
}

.typography__subhead {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 125%;
  letter-spacing: 0.03em;
  margin-top: 14px;

  @include smMaxBreakPoint {
    font-size: 26px;
  }
}
