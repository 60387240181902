@import '../../sass/variables';
@import '../../sass/mixins';

.login {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  &-wrapper {
    margin: auto;
    padding: 1em;
    text-align: center;
  }
  &-logo {
    width: 150px;
  }
  &-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &__label {
      color: $darkBlue;
      font-size: 1em;
      font-weight: 500;
      margin: 0;
      text-align: left;
      width: 100%;
    }
    input {
      border: 1px solid $lightGray;
      border-radius: 4px;
      font-size: 1em;
      margin-top: 20px;
      padding: 20px;
      width: 500px;
      @include smMaxBreakPoint {
        width: 350px;
        padding: 10px;
      }
      @include mobileMdMaxBreakPoint {
        width: 300px;
        padding: 10px;
      }
      @include mobileSmMaxBreakPoint {
        width: 250px;
        padding: 10px;
      }
    }
    &__signUp {
      background-color: $blue;
      border: 1px solid $blue;
      border-radius: 30px;
      box-shadow: 0px 6px 9px rgba(61, 96, 246, 0.4);
      color: $white;
      cursor: pointer;
      font-size: 1em;
      font-weight: 500;
      height: 60px;
      margin: 30px 0;
      width: 240px;
      &:hover {
        background-color: darken($color: $blue, $amount: 5);
      }
    }
  }
  &-socialButton {
    align-items: center;
    background-color: $white;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    color: $darkBlue;
    cursor: pointer;
    display: flex;
    font-size: 1em;
    height: 64px;
    justify-content: center;
    margin: 10px 0;
    width: 500px;
    &__apple {
      background-color: $black;
      color: $white;
    }
    svg {
      margin-right: 15px;
    }
    @include mobileLgMaxBreakPoint {
      width: 350px;
      height: 50px;
      font-size: 1em;
    }
  }
  &-helperText {
    font-size: 0.9em;
    color: $darkBlue;
    a {
      color: $blue;
    }
  }
}
