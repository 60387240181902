@import '../../sass/variables';
@import '../../sass/mixins';

.home-title {
    margin: 0 10%;
    line-height: 1.8em;
    h2 {
      color: $darkBlue;
      font-size: 1.6em;
      font-weight: 500;
    }
    h3 {
      color: $darkBlue;
      font-size: 1.4em;
      font-weight: 400;
    }
}