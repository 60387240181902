@import './sass/variables';
@import './sass/mixins';

.header {
  align-items: center;
  border-bottom: 1px solid $lightGray;
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 0 2em;
  &-logo {
    cursor: pointer;
    width: 73px;
    @include mobileLgMaxBreakPoint() {
      width: 55px;
    }
  }
  &-nav {
    align-items: flex-end;
    display: flex;
    height: 100%;
    &__item {
      color: $black;
      display: block;
      font-size: 0.9em;
      height: 40px;
      margin: 0 1em;
      text-decoration: none;
      &.active {
        color: $blue;
        border-bottom: 2px solid $blue;
      }
      @include mobileLgMaxBreakPoint() {
        font-size: 0.8em;
      }
    }
  }
  &-searchInput {
    border: 1px solid $lightGray;
    border-radius: 100px;
    height: 40px;
    outline: none;
    padding: 0 20px;
    width: 60%;
    &:focus {
      // background: #FCFCFE;
      border: 1px solid $blue;
      box-shadow: 0px 1px 14px rgba(61, 96, 246, 0.15);
    }
    @include mobileLgMaxBreakPoint() {
      display: none;
    }
  }
  &-button {
    background-color: red;
    // border: 2px solid $blue;
    // border-radius: 30px;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    height: 40px;
    width: 60px;
  }

  &-avatar {
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
  }
  @include mdMaxBreakPoint() {
    button {
      font-size: 20px;
      height: 25px;
      width: 25px;
    }
  }
}

.footer {
  color: white;
  height: 100px;
  margin-top: -100px;
  text-align: center;
  width: 100%;
  p {
    color: $extraDarkBlue;
    font-size: 1em;
  }
  img {
    height: 63px;
    width: 182px;
  }
}

.container {
  overflow: auto;
  margin: 0 10%;
  padding-bottom: 130px;
  min-height: calc(100vh - 180px);
  @include mobileLgMaxBreakPoint() {
    margin: 0;
  }
}
