@import '../../sass/variables';
@import '../../sass/mixins';



.top-button {

  flex-grow: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  border: 0px;
  height: 30px;
  width: 135px;
  padding: 0 16px;
  &:hover {
    cursor: pointer;
  }
  display: inline-flex;
  background: transparent;
}
