@import '../../sass/variables';
@import '../../sass/mixins';

.profile {
  margin: 0 10%;
  h2 {
    color: $darkBlue;
    font-size: 1.6em;
    font-weight: 500;
  }
  &-userInfo {
    align-items: center;
    display: flex;
    img {
      border-radius: 50%;
      height: 150px;
      width: 150px;
      margin-right: 2em;
    }
    &__username {
      p {
        color: $darkBlue;
        font-size: 1.6em;
        font-weight: 500;
      }
      button {
        background-color: $white;
        border: 1px solid $lightGray;
        border-radius: 6px;
        cursor: pointer;
        font-size: 0.9em;
        padding: 0.5em 2em;
      }
    }
    @include smMaxBreakPoint() {
      flex-direction: column;
      &__username {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      img {
        margin: 0;
      }
    }
  }
  &-data {
    cursor: pointer;
    margin-top: 2em;
    width: 100%;
    &__item {
      border-bottom: 1px solid $lightGray;
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      p {
        color: $darkBlue;
      }
    }
  }
}

.categories {
  &-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    padding: 0 5em;
    &__route {
      color: $darkBlue;
      font-size: 0.9em;
      font-weight: 500;
      margin: 0;
      svg {
        margin: 0 1em;
      }
      a {
        cursor: pointer;
        color: $darkBlue;
        opacity: 0.4;
        text-decoration: none;
      }
      a:last-child {
        opacity: 1;
      }
    }
    &__title {
      color: $darkBlue;
      font-size: 2em;
      font-weight: 500;
      margin: 0;
      margin-top: 0.8em;
    }
    &__add {
      background-color: $blue;
      border: none;
      border-radius: 6px;
      color: $white;
      height: 40px;
      outline: none;
      width: 100px;
    }
    @include smMaxBreakPoint() {
      padding: 0 2em;
      &__add {
        width: 60px;
        height: 30px;
      }
    }
  }
}
